import React from 'react'
import Nav from './nav'
import Footer from './footer'
import '../pages/index.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

export default props => (
  <div className="layout">
    <Nav />
    {props.children}
    <Footer />
  </div>
)
