import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap'

import logo from '../assets/images/logo-white-text.png'

export default class extends Component {
  state = {
    isOpen: false,
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  render() {
    return (
      <Navbar dark fixed="top" expand="md">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="" />
        </Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link to="#about" className="nav-link">
                About
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/team" className="nav-link">
                Team
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/portfolio" className="nav-link">
                Portfolio
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/press" className="nav-link">
                Press
              </Link>
            </NavItem>
            <NavItem>
              <Link to="#contact" className="nav-link">
                Contact
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}
