import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import PressButton from '../components/pressButton'

export default ({ data }) => (
  <Layout>
    <Container fluid className="press">
      <Row>
        <Col className="top-col page">
          <h1>Press</h1>
        </Col>
      </Row>
      <div className="press-grid">
        {data.press.edges.map(({ node }, index) => {
          const image = data.pressImages.edges.find(edge => {
            return (
              edge.node.relativePath === `press/${node.frontmatter.date}.jpg`
            )
          })
          const fluid = image.node.childImageSharp.fluid
          const { title, date, dateText, source } = node.frontmatter
          return (
            <PressButton
              key={node.id}
              link={node.fields.slug}
              id={node.id}
              title={title}
              date={date}
              dateText={dateText}
              source={source}
              fluid={fluid}
            />
          )
        })}
      </div>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    press: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "/press/.*/" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            dateText
            source
          }
          fields {
            slug
          }
        }
      }
    }
    pressImages: allFile(
      sort: { order: DESC, fields: [absolutePath] }
      filter: { relativePath: { regex: "/press/.*.jpg/" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
