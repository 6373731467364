import React from 'react'

export default props => (
  <footer>
    <div className="left-col-content">
      <div className="contact-info">
        <span>
          900 Bush Street, PH #1
          <br />
          San Francisco, CA 94109
        </span>
        <span>Tel. (415) 922-0449</span>
      </div>
    </div>
    {/* <a href="https://www.calvano.com">
      <img
        className="logo"
        src={require('../assets/images/logo-white-text.png')}
        alt="Calvano Development Logo"
      />
    </a> */}
    <div className="right-col-content">
      <div className="contact-info">
        <div>
          <span>
            &copy; 2019 Calvano Development <br />
            <a href="https://app.termly.io/document/privacy-policy/4dba74da-2e37-46a7-900d-2c4ae80619db">
              Privacy & Terms of Use
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
)
