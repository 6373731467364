import React from 'react'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

export default props => (
  <Link className="press-button" to={props.link}>
    <article>
      <h4>{props.title}</h4>
      <BackgroundImage
        Tag="section"
        className="press-highlight"
        fluid={props.fluid}
      />
      <span>
        {props.dateText}
        <br />
        <strong>{props.source}</strong>
      </span>
    </article>
  </Link>
)
